
export function toggleNav(){
  let header = document.querySelector('.header');
  let mainMenu = document.querySelector('.main-menu');
  let menuContainer = document.querySelector('.main-menu .mask');
  let sideMenu = document.querySelector('.secondary-menu');
  let menuPluses = Array.from(document.querySelectorAll('.plus'));
  
  if(mainMenu.classList.contains('active')){
    menuContainer.classList.remove('active');
    menuPluses.map(menuPlus => {
      return menuPlus.classList.remove('transform');
    });
    header.classList.remove('active');
    mainMenu.classList.remove('active');
    sideMenu.classList.remove('invert-color');
  }else{
    sideMenu.classList.add('invert-color');
    header.classList.add('active');
    mainMenu.classList.add('active');

    setTimeout(function(){
      menuContainer.classList.add('active');
    },750)
    
    menuPluses.map(menuPlus => {
      return menuPlus.classList.add('transform');
    });
  }
  return
}
