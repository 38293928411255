import React from 'react';
import styled from "styled-components"
import Menu from "./menu"
import Sidemenu from "./sidemenu";
import TransitionComponent from './transitionComponent';
import {GlobalStyle} from '../globalStyles';
import "../typography.css";
const PageWrap = styled.div`
  /* margin-top:174px; */
  width:100%;
  z-index:0;
  padding:0 1rem 2rem 1rem;

  @media only screen and (min-width: 550px) {
    padding:0 2rem 2rem 2rem;
  }
`;


const Layout = ({data, children }) => {

  return (
    <div className="global-wrapper" >
      <GlobalStyle />
      <Menu />
      <PageWrap>
          {children}
      </PageWrap>
      <Sidemenu />
      <TransitionComponent />
    </div>
  )
}

export default Layout
