import React, {useEffect, useState}  from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import {ImPlus} from 'react-icons/im';
import {BsCircleFill} from 'react-icons/bs';
import {toggleNav} from '../utils/toggleNav';
import {toggleColor} from '../utils/toggleColor';
import gsap from 'gsap';

function template(i, duration, tag) {
  return `
      &:nth-child(${i + 1}) ${tag} {
        transition-delay: ${`calc(${duration}s * ${i + 1})`};
       }
    `;
}

function getAnimations() {
  let str = '';
  for (let index = 0; index < 40; index += 1) {
      str += template(index, 0.07, 'span')
  }
  return str;
}

function menuClose(e){
  const scrolled = document.querySelector('.mask').scrollTop

  console.log(scrolled)
  let all = Array.from(document.querySelectorAll('.main-menu .mask a'));
  const difference = function (a, b) { return Math.abs(a - b); }
  const target = e.target.parentNode;
  const toHide = all.filter(result => result !== target);
  const menuMask = document.querySelector('.main-menu .mask');
  const logoVals = document.querySelector('.main-menu .mask a:first-of-type').getBoundingClientRect();
  const defaultY = logoVals.y;
  const targetVals = target.getBoundingClientRect();
  const targetY = targetVals.y;
  
  const tl =  gsap.timeline();
  tl.to(toHide, {opacity:0, duration:0.2})
  tl.to(target, {y:`-${difference(targetY, defaultY + scrolled)}`, duration:0.4, delay:-0.2})
  tl.call(toggleNav, null, '+=0.8')
  tl.call(function(){
    toHide.map(item => {
      item.style.removeProperty('opacity');
      item.style.removeProperty('transform');
    });
    target.style.removeProperty('transform');
    menuMask.scroll(0,0);
  }, null, '+=1.5')
}

const Menu = styled.nav`
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  transition:1s all ease-in-out;
  /* transition:1s all cubic-bezier(0.075, 0.82, 0.165, 1); */
  z-index:1000;
  pointer-events:none;
  /* Top to Bottom */
  /* clip-path:polygon(0% 0%,100% -100%,100% 0%,0% 0%); */
  /* Bottom to Top */
  clip-path:polygon(0% 100%,100% 100%,100% 100%,0% 100%);
  
  &:after{
    background:var(--text-color);
    width:100%;
    height:100%;
    content:"";
    top:0;
    left:0;
    position: fixed;
  }

  .mask{
    overflow: scroll;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 1rem 2rem;

    @media only screen and (min-width: 550px) {
      padding: 1.5rem 4rem;
    }
  }

  .plus{
    color:var(--bg-color);
    font-size:clamp(2rem, 4vw, 3rem);
    /* position: absolute; */
    /* top: 4rem;
    right: 4rem; */
    z-index: 1000;
    cursor: pointer;
    transition:0.5s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
    font-size: 18px;

    @media only screen and (min-width: 550px) {
      font-size: clamp(2rem,4vw,3rem);
    }
      
      &.transform{
      transform:rotate(45deg);
      }
  }

  .fakeHeader{
    padding: 1rem 2rem;
    position: absolute;
    width: 100%;

    @media only screen and (min-width: 550px) {
      padding: 1.5rem 4rem 2rem 2rem;
    }

    .wrapper{
      display:flex;
      justify-content:space-between;
      align-items:center;
    }


    .logo{
      /* width:560px; */
      color:var(--text-color);;
      font-size: clamp(1.7rem, 6vw, 6rem);
      font-family: 'Oswald', sans-serif;
      text-decoration:none;
      text-transform:uppercase;
      transition:0.5s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

  }

  .fakeHeaderPlus{
    &.hide{
    opacity:0;
    pointer-events:none;
    display:none;
    }
  }


  .icon-wrap{
    position: relative;
    top: 1rem;
    right: 0;
    transition:0.25s all ease-in-out;
    transform:translateX(200%);
    z-index: 1000;
    right: 0;
    


    @media only screen and (min-width: 550px) {
    right: 1rem;
    top: 2rem;
    position: relative;
    }

    &.show{
      transform:translateX(0%);
    }
 
    .plus,
    .circle{
      position:absolute;
      right:6px;
      top:0;
      color:var(--bg-color);
      z-index: 990000000000;
      cursor: pointer;
      transition:0.5s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
      pointer-events:all;
      margin-bottom:1rem;
      font-size: 18px;

      @media only screen and (min-width: 550px) {
      font-size: 30px;
      margin-bottom:2rem;
      right:3px;
      }
      
  
        &.transform{
        transform:rotate(45deg);
        }
    }

    .circle{
    top:38px;
      @media only screen and (min-width: 550px) {
      top:65px;
      }
    }

  }

  a{
    z-index:10;
    position: relative;
    color:var(--bg-color);
    font-family: 'Oswald', sans-serif;
    text-decoration:none;
    text-transform:uppercase;
    cursor: pointer;
    pointer-events:none;
    opacity:.2;
    width:100%;
    transition:0.5s all ease-in-out;
    font-size: clamp(1.7rem, 8vw, 6rem);
    /* font-size:6rem; */
    
    span{
      transition:0.5s all ease-in-out;
      opacity:0;
      transform:translateY(60px);
      display: block;
    }

    ${getAnimations()}

    &.hidden{
      opacity:0 !important;
      transform:translateX(100%);
    }
  }

  &.active{
  pointer-events:all;
  clip-path:polygon(0 0, 100% 0, 100% 100%, 0% 100%);

    .mask.active{
      a{
        opacity:.2;
        pointer-events:all;
  
        &[aria-current="page"]{
         opacity:1; 
        }
  
        span{
          opacity:1;
          transform:translateY(0);
        }
  
        &:hover{
        opacity:1;
        }
      }
    }

  }
`;


export default (props) => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "main-menu" }) {
        name
        menuItems {
          nodes {
            label
            url
            parentId
            target
            connectedNode {
              node {
                ... on WpContentNode {
                  uri
                }
              }
            }
          }
        }
      }
    }
  `)
  
  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <Menu className="main-menu">
      <div className="fakeHeader">
        <div className="wrapper">
          <div className="logo hidden">test</div>
          <ImPlus className="plus fakeHeaderPlus" onClick={toggleNav}/>
        </div>
      </div>
        <div className="icon-wrap">
          <ImPlus title="Show Menu" className="plus" onClick={toggleNav}/>
          <BsCircleFill title="Change Colour" className="circle black" onClick={toggleColor} />
        </div>
      
        
        <div className="mask">
          {wpMenu.menuItems.nodes.map((menuItem, i) => {
                if (menuItem.parentId) {
                  return null
                }
    
                const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url
    
                return (
                  <Link
                    key={i + menuItem.url}
                    style={{ display: `block` }}
                    to={menuItem.target === '_blank' ? path : path}
                    target={menuItem.target}
                    onClick={(e) => menuClose(e)}
                  >
                    <span>{menuItem.label}</span>
                  </Link>
                )
              })}
        </div>
      </Menu>
  ) : null
}

