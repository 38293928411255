import { createGlobalStyle } from 'styled-components';
 
export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    --bg-color: #fff;
    --text-color: #000;
    --text-color-switcher: #000000;
  }

  body {
    /* min-height: 100vh; */
    color: var(--text-color);
    background: var(--bg-color);
    transition: 1.5s all cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden;
  }

  .scroller {
    width: calc(100% - 4rem);
    min-height: 100%;
    position: fixed;
    top: 0;

      @media only screen and (min-width: 550px) {
        width: calc(100% - 8rem);
      }

  }

  p {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
    padding: 0 !important;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
  }

  /* .wrapper {
    max-width: 1400px;
    margin: 0 auto;
  } */

  .tl-wrapper {
    z-index: 0 !important;
  }

  .global-wrapper .secondary-menu,
  .global-wrapper .transitionComponent {
    display: none;
  }

  .global-wrapper .global-wrapper .transitionComponent,
  .global-wrapper .global-wrapper .secondary-menu {
    display: block;
  }

`;
 

