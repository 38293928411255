import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import {ImFacebook} from 'react-icons/im';
import {ImTwitter} from 'react-icons/im';
import {FiInstagram} from 'react-icons/fi';
import {GrLinkedinOption} from 'react-icons/gr';
// import { Link} from "gatsby"
import Link from 'gatsby-plugin-transition-link'
import {pageTransitionIn, pageTransitionOut} from '../transitions/pageTransition';

const SideMenu = styled.aside`
  position: fixed;
  right:0;
  height:100%;
  top: 0;
  pointer-events:none;
  z-index:1000;
  width: 3rem;

    @media only screen and (min-width: 550px) {
      width: 2rem;
    }
  
  &::nth-of-type(2){
    display:none;
  }

  @media only screen and (min-width: 550px) {
    width: 4rem;
  }

  .center{
  transform:rotate(90deg) translateY(-41px) translateX(-7px);
  display: flex;
  transform-origin: top left; 
  width:100vh;
  justify-content: flex-end;

    a{
    margin-right:20px;
    text-transform:uppercase;
    font-family:"Oswald";
    display:inline;
    position: relative;
    text-decoration:none;
    color:var(--text-color);
    transition:0.5s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
    pointer-events:all;
    font-size:13px;

      @media only screen and (min-width: 550px) {
        font-size:inherit;
      }


      &[aria-current="page"]{
       opacity:0.5; 
      }
      &:hover{
        opacity:0.5;
      }
    }
  }
  
  .icon_link{
  transform:rotate(-90deg);
  }

  &.invert-color{
    /* .icon-wrap{
      .circle,
      .plus{
        color:var(--bg-color);
      }
    } */
    .center{
      a{
        color:var(--bg-color);
      }
    }
  }
`
function closeNav(){
  let header = document.querySelector('.header');
  let mainMenu = document.querySelector('.main-menu');
  let sideMenu = document.querySelector('.secondary-menu');
  let menuPluses = Array.from(document.querySelectorAll('.plus'));
  
  if(header.classList.contains('active')){
    menuPluses.map(menuPlus => {
      return menuPlus.classList.remove('transform');
    });
    header.classList.remove('active');
    mainMenu.classList.remove('active');
    sideMenu.classList.remove('invert-color');
  }
  
}

export default (props) => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "secondary-menu" }) {
        name
        menuItems {
          nodes {
            label
            url
            parentId
            target
            connectedNode {
              node {
                ... on WpContentNode {
                  uri
                }
              }
            }
          }
        }
      }
    }
  `)
  
  
  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <SideMenu className="secondary-menu">
    
      <div className="center">
    	  {wpMenu.menuItems.nodes.map((menuItem, i) => {
          if (menuItem.parentId) {
            return null
          }
       
          const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url
          
          return (
            <Link
              key={i + menuItem.url}
              style={{ display: `block` }}
              to={menuItem.target === '_blank' ? path : path}
              target={menuItem.target}
              onClick={closeNav}
              exit={{
                trigger: pageTransitionIn,
                length: 1
              }}
              entry={{
                trigger: pageTransitionOut,
                delay: 1
              }}
            >
            
              {menuItem.label}
            </Link>
          )
        })}
       <a className="icon_link" href="https://instagram.com/photosbydavidlindsay" rel="noreferrer" aria-label="Link" target="_blank"><FiInstagram/></a>
       <a className="icon_link" href="https://facebook.com/photosbydavidlindsay" rel="noreferrer" aria-label="Link" target="_blank"><ImFacebook/></a>
       <a className="icon_link" href="https://twitter.com/photosbydavid" rel="noreferrer" aria-label="Link" target="_blank"><ImTwitter/></a>
       <a className="icon_link" href="https://linkedin.com//in/photosbydavid" rel="noreferrer" aria-label="Link" target="_blank"><GrLinkedinOption/></a>
      </div>
    </SideMenu>
  ) : null
}
