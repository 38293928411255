export function toggleColor(){
  let root = document.documentElement;
  let circles = Array.from(document.querySelectorAll('.circle'));
  let textColour1 = '#000000';
  let textColour2 = '#ffffff';
  circles.map(circle => {
    // console.log('clicked');
    if(circle.classList.contains('black')){
      root.style.setProperty('--text-color', 'white');
      root.style.setProperty('--bg-color', 'black');
      root.style.setProperty('--text-color-switcher', textColour2);
      circle.classList.remove('black');
      return '';
    }else{
      root.style.setProperty('--text-color', 'black');
      root.style.setProperty('--bg-color', 'white');
      root.style.setProperty('--text-color-switcher', textColour1);
      circle.classList.add('black');
      return '';
    }
  });
  
}