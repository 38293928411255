import React from "react";
import styled from "styled-components"

const TransitionComponentWrap = styled.div`
 position: fixed;
 background:var(--text-color);
 width:100vw;
 height:100vh;
 z-index:10000000000;
 top:0;
 left:0;
 transform:translateY(100%);
 transition:1s transform ease-in-out;
 
 &.transform-in{
   transform:translateY(0%);
 }

 &.transform-out{
   transform:translateY(-100%);
 }

`;

export default function TransitionComponent(props) {
  return (
    <TransitionComponentWrap className="transitionComponent">
      
    </TransitionComponentWrap>
  )
}

