//fonts
// import "fontsource-oswald/600.css"
import "fontsource-pt-serif"

// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Import and Layout wrapPageElement
import React from "react";
import Layout from "./src/components/layout";
    
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
