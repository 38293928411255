export function pageTransitionIn(){
  const transitionComponent = document.querySelector('.transitionComponent');
  transitionComponent.classList.add('transform-in');
}
export function pageTransitionOut(){
  const transitionComponent = document.querySelector('.transitionComponent');
  transitionComponent.classList.add('transform-out');

  setTimeout(function(){
    transitionComponent.style.opacity = "0"
    transitionComponent.classList.remove('transform-in');
    transitionComponent.classList.remove('transform-out');
  },1000)
  setTimeout(function(){
    transitionComponent.style.removeProperty('opacity');
  },2000)
}